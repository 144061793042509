import dynamic from "next/dynamic"
import { ImageProps } from "next/image"
import { ReactNode } from "react"

import { AppHead } from "@/lib/components/shared/app-head"
import { GlobalFieldsContext } from "@/lib/context/acf-global"
import { ImageMapContext } from "@/lib/context/image-map"
import { WPGlobalFields, WPSeoFields } from "@/lib/models/common"

import QuizBanner from "../external/quiz-banner"
import WhitepaperCloseModal from "../whitepaper-modal/whitepaper-onclose"

interface LayoutProps {
  children: ReactNode
  globalFields: WPGlobalFields
  imageMap?: Record<number, ImageProps>
  seo: WPSeoFields
}

const DynamicHeader = dynamic(() => import("./../../components/header/Header"))
const DynamicFooter = dynamic(() => import("./../../components/footer/Footer"))

export const Layout = ({ children, globalFields, imageMap, seo }: LayoutProps) => {
  return (
    <GlobalFieldsContext.Provider value={globalFields.acfGlobalFields.acfGlobal}>
      <ImageMapContext.Provider value={imageMap ?? {}}>
        <AppHead {...seo} />

        <DynamicHeader />
        {children}
        <DynamicFooter />
        <WhitepaperCloseModal />
      </ImageMapContext.Provider>
    </GlobalFieldsContext.Provider>
  )
}
