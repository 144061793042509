import classNames from "classnames"

import { DownloadIcon } from "@/lib/components/icons/downloadIcon"

interface DownloadButtonProps {
  label: string
  onClick?: () => void
  type: "button" | "submit"
  disable?: boolean
  className?: string
}

export const DownloadButton = ({
  label,
  onClick,
  type,
  disable,
  className,
}: DownloadButtonProps) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={classNames(
        "cta-btn-full bg-cta-orange font-semibold leading-4 after:bg-cta-orange-highlight",
        className
      )}
      disabled={disable}
    >
      {label}
      <DownloadIcon className="ml-2.5" />
    </button>
  )
}
