import { useId } from "react"

import classNames from "classnames"
import { UseFormRegisterReturn } from "react-hook-form"

import { ErrorIcon } from "@/lib/components/icons/error-icon"
import { RightArrowIcon } from "@/lib/components/icons/right-arrow"
import { Wysiwyg } from "@/lib/components/shared/Wysiwyg"

export interface FormItemProps {
  label: string
  inputType: "text" | "newsEmail" | "email" | "textarea" | "checkbox"
  formRegister: UseFormRegisterReturn
  hasInputError?: boolean
  errorMessage?: string
  isSubmitting?: boolean
}

export const FormItem = ({
  label,
  inputType,
  formRegister,
  hasInputError,
  errorMessage,
  isSubmitting,
}: FormItemProps) => {
  const formId = useId()
  let formItem = null

  const defaultGdprLabel = "Jag accepterar era <a href='/policy'>användarvillkor</a>"

  switch (inputType) {
    case "checkbox":
      formItem = (
        <label htmlFor={formId} className="flex items-center space-x-2">
          <input
            type="checkbox"
            id={formId}
            className={classNames(
              "form-checkbox border border-[#A5A9AA] bg-white checked:border-dark-blue checked:bg-light-blue",
              {
                "border-error-red-600 bg-error-red-50": hasInputError,
              }
            )}
            {...formRegister}
          />
          <Wysiwyg
            content={label ?? defaultGdprLabel}
            className="prose text-body-xs prose-a:underline-offset-1 prose-a:transition-colors prose-a:duration-300 hover:prose-a:text-cta-orange"
          />
        </label>
      )
      break

    case "newsEmail":
      formItem = (
        <div className="flex flex-col">
          <div
            className={classNames("mb-4 flex w-full items-center border-b border-gray-warm-900", {
              "border-b-2 border-error-red-500": hasInputError,
            })}
          >
            <input
              type="email"
              placeholder={label}
              className="inline-block flex-1 bg-transparent py-2.5 pr-3 outline-none placeholder:text-gray-warm-700"
              {...formRegister}
            />

            <button
              type="submit"
              className="cta-btn-full flex h-8 w-8 items-center justify-center rounded-full bg-cta-orange p-0 after:bg-cta-orange-highlight"
              disabled={isSubmitting}
            >
              <RightArrowIcon stroke="black" />
            </button>
          </div>
          {hasInputError && (
            <p className="mb-4 flex text-body-xs text-error-red-500">
              <ErrorIcon className="mr-2" /> {errorMessage || "Ange en giltig e-post."}
            </p>
          )}
        </div>
      )
      break

    case "email":
      formItem = (
        <div className="relative">
          <input
            type="email"
            className={classNames(
              "w-full rounded-md border border-[#D9DCDD] px-4 pb-[15px] pt-[17px] leading-4 outline-none",
              {
                "!border-error-red-500 bg-error-red-50 placeholder:text-error-red-300":
                  hasInputError,
              }
            )}
            placeholder={label}
            {...formRegister}
          />
          {hasInputError && (
            <>
              <ErrorIcon className="absolute right-4 top-5" />
              <span className="z-30 mt-1 flex  text-body-xs text-error-red-500">
                {errorMessage || "Ange en giltig e-post."}
              </span>
            </>
          )}
        </div>
      )
      break

    case "text":
      formItem = (
        <div className="relative">
          <input
            type="text"
            placeholder={label}
            className={classNames(
              "w-full rounded-lg border border-gray-warm-200 px-4 pb-[15px] pt-[17px] outline-none",
              {
                "!border-error-red-500 bg-error-red-50 placeholder:text-error-red-300":
                  hasInputError,
              }
            )}
            {...formRegister}
          />
          {hasInputError && (
            <>
              <ErrorIcon className="absolute right-4 top-5" />
              <span className="z-30 mt-1 flex  text-body-xs text-error-red-500">Ange ett name</span>
            </>
          )}
        </div>
      )
      break

    case "textarea":
      formItem = (
        <div className="relative">
          <textarea
            className={classNames(
              "w-full rounded-md border border-[#D9DCDD] p-4 pt-[17px] leading-4 outline-none",
              {
                "!border-error-red-500 bg-error-red-50 placeholder:text-error-red-300":
                  hasInputError,
              }
            )}
            rows={5}
            placeholder={label}
            {...formRegister}
          />
          {hasInputError && (
            <>
              <ErrorIcon className="absolute right-4 top-5" />
              <span className="z-30 flex  text-body-xs text-error-red-500">
                Ange ett meddelande
              </span>
            </>
          )}
        </div>
      )
      break

    default:
      formItem = (
        <div className="relative">
          <input
            type={inputType}
            placeholder={label}
            className={classNames(
              "w-full rounded-lg border border-gray-warm-200 px-4 pb-[15px] pt-[17px] outline-none",
              {
                "!border-error-red-500 bg-error-red-50 placeholder:text-error-red-300":
                  hasInputError,
              }
            )}
            {...formRegister}
          />
          {hasInputError && (
            <>
              <ErrorIcon className="absolute right-4 top-5" />
              <span className="z-30 mt-1 flex  text-body-xs text-error-red-500">Ange ett name</span>
            </>
          )}
        </div>
      )
  }

  return formItem
}
