import { IconProps } from "./"

export const RightArrowIcon = ({ className, stroke = "white" }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3335 6H14.6668M14.6668 6L9.66683 1M14.6668 6L9.66683 11"
        stroke={stroke}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
