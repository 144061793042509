import { CSSProperties, ForwardedRef, forwardRef } from "react"

import classNames from "classnames"

interface WysiwygProps {
  content: string
  className?: string
  style?: CSSProperties
}

const BaseWysiwyg = (
  { content, className, style }: WysiwygProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  return (
    <div
      ref={ref}
      className={classNames("wysiwyg", className)}
      dangerouslySetInnerHTML={{ __html: content }}
      style={style}
    ></div>
  )
}

export const Wysiwyg = forwardRef(BaseWysiwyg)
