import Head from "next/head"

import { WPSeoFields } from "@/lib/models/common"

export const AppHead = ({
  title,
  metaDesc,
  canonical,
  opengraphDescription,
  opengraphImage,
  opengraphTitle,
  twitterDescription,
  twitterImage,
  twitterTitle,
}: WPSeoFields) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={metaDesc} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={opengraphDescription || metaDesc} />
      <meta property="og:title" content={opengraphTitle} />
      <meta property="twitter:title" content={twitterTitle || title} />
      <meta property="twitter:description" content={twitterDescription || metaDesc} />
      <meta property="twitter:title" content={twitterTitle ? twitterTitle : title} />
      <meta
        property="twitter:description"
        content={twitterDescription ? twitterDescription : metaDesc}
      />
      <meta property="og:url" content={canonical} />
      <link rel="canonical" href={canonical} />

      {/* SEO - image */}
      {(opengraphImage || twitterImage) && (
        <>
          <meta
            property="og:image"
            content={opengraphImage?.mediaItemUrl || twitterImage?.mediaItemUrl}
          />
          <meta
            property="twitter:image"
            content={twitterImage?.mediaItemUrl || opengraphImage?.mediaItemUrl}
          />
        </>
      )}
    </Head>
  )
}
