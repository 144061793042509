import { useState } from "react"

import handIcon from "/public/images/hand.png"
import classNames from "classnames"

import { WhitepaperForm } from "@/lib/components/forms/whitepaper"
import { Wysiwyg } from "@/lib/components/shared/Wysiwyg"
import Image from "@/lib/components/shared/app-image"
import { AcfWhitepapersBox } from "@/lib/models/common"

interface WhitepaperCardProps extends AcfWhitepapersBox {
  isPopup?: boolean
}

export const WhitepaperCard = ({
  upsalesFormId,
  upsalesNameKey,
  upsalesEmailKey,
  upsalesGdprKey,
  title,
  description,
  namePlaceholder,
  emailPlaceholder,
  terms,
  buttonLabel,
  submitMessage,
  errorButtonLabel,
  errorMessage,
  isPopup,
}: WhitepaperCardProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  return (
    <div
      className={classNames(
        "flex w-full flex-col bg-white",
        isPopup && "rounded-br-3xl rounded-tr-3xl p-10",
        !isPopup && "rounded-2xl px-8 py-12 shadow-cards  md:px-8 md:py-8 lg:w-[397px] "
      )}
    >
      {!isSubmitted && (
        <div className="mb-8 flex flex-col">
          <h3 className="mb-2 text-display-xs font-semibold">{title}</h3>
          <p>{description}</p>
        </div>
      )}

      {!isSubmitted && (
        <WhitepaperForm
          upsalesFormId={upsalesFormId}
          upsalesNameKey={upsalesNameKey}
          upsalesEmailKey={upsalesEmailKey}
          upsalesGdprKey={upsalesGdprKey}
          namePlaceholder={namePlaceholder}
          emailPlaceholder={emailPlaceholder}
          gdpr={terms}
          buttonLabel={buttonLabel}
          setSubmitted={setIsSubmitted}
          errorButtonLabel={errorButtonLabel}
          errorMessage={errorMessage}
          isFromPopup={isPopup}
        />
      )}

      {isSubmitted && (
        <div className="flex min-h-[300px] flex-col items-center">
          <div className="relative h-32 w-32">
            <Image src={handIcon} alt="Icon av en hand" fill object-cover />
          </div>
          <Wysiwyg
            className="prose text-center prose-h2:mb-2 prose-h2:text-display-sm prose-h2:font-semibold prose-p:text-body-xl"
            content={submitMessage}
          />
        </div>
      )}
    </div>
  )
}
