import { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import classNames from "classnames"
import { SubmitHandler, useForm } from "react-hook-form"

import { DownloadButton } from "@/lib/components/download-article-btn/download-article-btn"
import { FormWhitepaperSchema, FormWhitepaperTypes } from "@/lib/components/schemas/form-whitepaper"

import { FormItem } from "./form-item"

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

interface WhitepaperFormProps {
  upsalesFormId: string
  upsalesNameKey: string
  upsalesEmailKey: string
  upsalesGdprKey: string
  namePlaceholder: string
  emailPlaceholder: string
  gdpr: string
  buttonLabel: string
  className?: string
  errorButtonLabel: string
  errorMessage: string
  setSubmitted: (value: boolean) => void
  isFromPopup?: boolean
}

export const WhitepaperForm = ({
  upsalesFormId,
  upsalesNameKey,
  upsalesEmailKey,
  upsalesGdprKey,
  namePlaceholder,
  emailPlaceholder,
  gdpr,
  buttonLabel,
  className,
  errorButtonLabel,
  errorMessage,
  setSubmitted,
  isFromPopup = false,
}: WhitepaperFormProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormWhitepaperTypes>({ resolver: zodResolver(FormWhitepaperSchema) })
  const [submitError, setSubmitError] = useState(false)
  const hasValidationError = Object.keys(errors).length > 0

  const onSubmit: SubmitHandler<FormWhitepaperTypes> = async data => {
    const response = await fetch("/api/download-whitepaper", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        formId: upsalesFormId,
        nameKey: upsalesNameKey,
        emailKey: upsalesEmailKey,
        gdprKey: upsalesGdprKey,
        ...data,
      }),
    })

    if (response.ok) {
      // form success submission tracking
      window.dataLayer = window.dataLayer || []

      if (isFromPopup) {
        window.dataLayer.push({ event: "whitepaper_download_popup" })
      } else {
        window.dataLayer.push({ event: "whitepaper_download" })
      }

      setSubmitError(false)
      setSubmitted(true)
      reset()
    } else {
      setSubmitError(true)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classNames(className)}>
      <div className="flex flex-col space-y-4">
        <FormItem
          inputType="text"
          label={namePlaceholder}
          formRegister={register("name")}
          hasInputError={errors.name !== undefined}
        />
        <FormItem
          inputType="email"
          label={emailPlaceholder}
          formRegister={register("email")}
          hasInputError={errors.email !== undefined}
          errorMessage={errors.email?.type === "custom" ? errors.email?.message : ""}
        />

        <FormItem
          inputType="checkbox"
          label={gdpr}
          formRegister={register("gdpr")}
          hasInputError={errors.gdpr !== undefined}
        />
        <DownloadButton
          label={hasValidationError || submitError ? errorButtonLabel : buttonLabel}
          type="submit"
          disable={isSubmitting}
          className={hasValidationError || submitError ? "bg-error-red-600 !text-white" : ""}
        />

        {(hasValidationError || submitError) && (
          <div className="mt-4 text-center text-body-sm text-error-red-500">
            {hasValidationError
              ? "Dubbelkolla rödmarkerade fält och försök igen"
              : submitError
              ? errorMessage
              : ""}
          </div>
        )}
      </div>
    </form>
  )
}
