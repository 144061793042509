import { z } from "zod"

import { isPersonalEmail } from "@/lib/utils/forms"

export const FormWhitepaperSchema = z.object({
  name: z.string().min(1),
  email: z
    .string()
    .email()
    .refine(val => !isPersonalEmail(val), { message: "Använd en giltig företagsmail" }),
  gdpr: z.literal(true),
})

export const FormWhitepaperUpsalesSchema = FormWhitepaperSchema.extend({
  formId: z.string().min(1),
  nameKey: z.string(),
  emailKey: z.string(),
  gdprKey: z.string(),
})

export type FormWhitepaperTypes = z.output<typeof FormWhitepaperSchema>
