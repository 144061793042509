import { Fragment, useState, useEffect } from "react"

import { Dialog, Transition } from "@headlessui/react"
import { useExitIntent } from "use-exit-intent"

import { WhitepaperCard } from "@/lib/components/cards/whitepaper-card"
import { CloseIcon } from "@/lib/components/icons/closeIcon"
import { Wysiwyg } from "@/lib/components/shared/Wysiwyg"
import { useGlobalFields } from "@/lib/context/acf-global"
import { useResponsiveLG } from "@/lib/hooks/shared"

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export default function WhitepaperCloseModal() {
  const isResponsiveLG = useResponsiveLG()

  const { unsubscribe, registerHandler } = useExitIntent()

  let [isOpen, setIsOpen] = useState(false)

  registerHandler({
    id: "openModal",
    handler: () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: "popup_exitIntent" })

      setIsOpen(true)
      unsubscribe()
    },
  })

  const {
    whitepaper: {
      popupDescription,
      popupTitle,
      whitepaper: {
        acfPostFields: { downloadWhitepaper },
      },
    },
  } = useGlobalFields()

  return (
    <>
      {isResponsiveLG && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-[100]" onClose={() => false}>
            <div className="fixed inset-0 bg-black bg-opacity-30" />

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-8 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-700"
                  enterFrom="opacity-0 scale-0"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="shadow-xl flex w-full max-w-4xl transform rounded-full  bg-white text-left align-middle transition-all">
                    <div className="flex w-1/2  flex-col rounded-bl-3xl rounded-tl-3xl bg-light-blue p-10 text-gray-warm-900">
                      <p className="pb-4 text-body-xl">Innan du går...</p>
                      <h2 className="pb-6 text-display-sm font-bold">{popupTitle}</h2>
                      <Wysiwyg content={popupDescription} className="text-body-lg " />
                    </div>
                    <div className="flex w-1/2">
                      <WhitepaperCard {...downloadWhitepaper} isPopup={true} />
                    </div>

                    <div className="t-[-20px] absolute right-[-26px] top-[-20px]">
                      <button
                        className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-black p-2 font-bold text-white transition-colors ease-in-out hover:bg-gray-700"
                        onClick={() => setIsOpen(false)}
                      >
                        <CloseIcon stroke={"white"} className="h-[20px] w-[20px]" />
                      </button>
                    </div>
                    {/* </Dialog.Title> */}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  )
}
