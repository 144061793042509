import invariant from "./invariant"

export function isPersonalEmail(email: string) {
  invariant(
    process.env.NEXT_PUBLIC_PERSONAL_EMAIL_DOMAINS,
    "NEXT_PUBLIC_PERSONAL_EMAIL_DOMAINS is undefined"
  )

  const domains = new Set(process.env.NEXT_PUBLIC_PERSONAL_EMAIL_DOMAINS.split("|"))
  const emailDomain = email.split("@")[1]

  return domains.has(emailDomain)
}
